import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import OurStory from "../Components/Our Story/OurStory";
import { Helmet } from "react-helmet";

export default function Story() {
  return (
    <>
      <Helmet>
        <title>Our Journey - Manzil Center | Special Families Support</title>
        <meta
          name="description"
          content="The genesis of Manzil was a non-profit support group called ‘Special Families Support’ which was started by our Director Dr. Ayesha Husaini on 9th December 1999."
        />
        <meta name="keywords" content="Manzil center, Special Family Support, Manzil Center Journey, special need school, Autism Center in Sharjah, NGO in Sharjah, Sharjah, UAE, special EMPLOYMENT PROGRAM in sharjah" />

        <link rel="canonical" href="https://www.manzil.ae/our-story" />
        <meta
          property="og:title"
          content="Our Journey - Manzil Center | Special Families Support"
        />
        <meta
          property="og:description"
          content="The genesis of Manzil was a non-profit support group called Special Families Support’ which started on 9th December, 1999."
        />
        <meta property="og:url" content="https://www.manzil.ae/our-story" />
        <meta
          property="og:image"
          content="https://www.manzil.ae/static/media/our-journy.878757edefe2017acb36.jpg"
        />
        <meta
          name="twitter:title"
          content="Our Journey - Manzil Center | Special Families Support"
        />
        <meta
          name="twitter:description"
          content="The genesis of Manzil was a non-profit support group called ‘Special Families Support’ which was started by our Director Dr. Ayesha Husaini on 9th December 1999."
        />
        <meta
          name="twitter:image"
          content="https://www.manzil.ae/static/media/our-journy.878757edefe2017acb36.jpg"
        />
      </Helmet>

      <NavBar />
      <div className="page-title-area">
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
          <div className="page-title-content">
            <ul>
              {/* <li>
                <a href="/">Home</a>
              </li> */}
              <li>
                <h1>Our Story</h1>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <OurStory />
      <Footer />
    </>
  );
}
